import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { ActionButton, ImageContainer, NoImageButton } from './styles';

import useI18n from '../../../../lib/use_i18n';
import Icon from '../../icon';
import InlineConfirmation from '../../inline_confirmation';
import { useModal } from '../../modal';
import { Flex } from '../../styles/flex';
import { ErrorWrapper } from '../../styles/form';
import { Field } from '../field';
import { Label } from '../label';
import { getError } from '../utils';
import { ImageUploadModal } from './modal';
import { useGenerateImageUploaderValues } from './use_generate_image_uploader_values';

/**
 * Shows an image with edit and delete buttons.
 *
 * Available props:
 *   allowSelect<bool>: there are cases (company logo), where we do not support selecting an existing image.
 *                      the value is true by default
 *   alt<string>: alt text for the displayed image
 *   editId<string>: ID for the edit button
 *   formatHint<string>: text hint about accepted format
 *   handleDelete<function>: image delete callback, receives an image object
 *   handleError<function>: receives the error text or a falsy value, and a boolean value indicating is validation in progress
 *   handleRename<function>: receives an image obj {id, name, ...}. It is called after user tries to rename the image.
 *   handleUpload<function>: receives the file to be uploaded, as well as onSuccess, onError, and onUploadProgress callbacks
 *   height<integer>: Height (in pixels) for the image container
 *   imageUrl<string>: URL to image, if empty it will show "No image", if present it shows the image
 *   images<array[obj]>: an array of images to choose from if the allowSelect is true.
 *                       image object should have a name and an url props
 *   instantValidation<bool>: when true, the given image url will be validated. By default, the image isn't validated we assume
 *                            it is uploaded on our servers.
 *   maxFileSize<integer>: Max allowed size in bytes for images (default 1Mb)
 *   maxWidth<integer>: Max width (in pixels) to accept the image. If null or empty this validation will be skipped
 *   minWidth<integer>: Min width (in pixels) to accept the image. If null or empty this validation will be skipped
 *   modalTitle<string>: text for the modal title
 *   successMsg<string>: text for the success Flash message
 *   uploadErrorMsg<string>: text for the upload error message
 *   validFileFormats<array[string]>: Array of file extensions for accepted images
 *   validationError<string>: A validation error from the backend
 *   width<integer>: Width (in pixels) for the image container
 */

const ImageUploader = ({
  allowSelect,
  alt,
  editId,
  fetchImages,
  formatHint,
  handleDelete,
  handleError,
  handleUpload,
  handleRename,
  height,
  imageUrl: initialImageUrl,
  instantValidation,
  maxFileSize,
  maxWidth,
  minWidth,
  modalTitle,
  name,
  onChange,
  successMsg,
  uploadErrorMsg,
  validFileFormats,
  validationError,
  width,
}) => {
  const { translate } = useI18n('image_uploader');
  const { Modal, open: openModal, close: closeModal } = useModal();
  const { handleRemoveConfirmation, handleSelect, imageUrl, showImage } =
    useGenerateImageUploaderValues({
      handleError,
      initialImageUrl,
      instantValidation,
      onChange,
      validationError,
    });
  return (
    <div>
      <Flex justifyContent="space-between">
        <ImageContainer imageHeight={height} imageWidth={width}>
          {showImage ? (
            <img src={imageUrl} alt={alt || name} />
          ) : (
            <NoImageButton onClick={openModal} data-testid="no-image-icon">
              <Icon name="image" size={30} />
              <div className="no-image">{translate('.no_image')}</div>
              <div className="select-image">{translate('.select_img')}</div>
            </NoImageButton>
          )}
        </ImageContainer>

        <Flex gap="4px">
          <ActionButton
            type="button"
            className="btn btn-default image-button-edit"
            id={editId}
            aria-label={translate('app.actions.edit')}
            data-balloon-pos="up"
            onClick={openModal}
            data-testid="imageUploader-edit"
          >
            <Icon name="edit" />
          </ActionButton>

          <InlineConfirmation
            onConfirmation={handleRemoveConfirmation}
            confirmClass="btn-danger"
            cancelClass="locations-button-cancel"
          >
            {(confirmationCallback) => (
              <span
                data-balloon-pos="up-right"
                aria-label={
                  !imageUrl
                    ? translate('.cant_delete')
                    : translate('app.actions.remove')
                }
              >
                <ActionButton
                  type="button"
                  className="btn btn-default"
                  onClick={confirmationCallback}
                  disabled={!imageUrl}
                  data-testid="imageUploader-delete"
                >
                  <Icon name="hide_image" />
                </ActionButton>
              </span>
            )}
          </InlineConfirmation>
        </Flex>
      </Flex>

      <ImageUploadModal
        Modal={Modal}
        allowSelect={allowSelect}
        close={closeModal}
        formatHint={formatHint}
        handleDelete={handleDelete}
        handleSelect={handleSelect}
        handleUpload={handleUpload}
        handleRename={handleRename}
        fetchImages={fetchImages}
        maxFileSize={maxFileSize}
        maxWidth={maxWidth}
        minWidth={minWidth}
        modalTitle={modalTitle}
        successMsg={successMsg}
        selectedImageUrl={imageUrl}
        uploadErrorMsg={uploadErrorMsg}
        validFileFormats={validFileFormats}
      />

      <input
        type="hidden"
        name={name}
        id={`${name}_input`}
        value={imageUrl || ''}
      />
    </div>
  );
};

ImageUploader.propTypes = {
  allowSelect: PropTypes.bool,
  alt: PropTypes.string,
  editId: PropTypes.string,
  fetchImages: PropTypes.func,
  formatHint: PropTypes.string,
  handleDelete: PropTypes.func,
  handleError: PropTypes.func,
  handleRename: PropTypes.func,
  handleUpload: PropTypes.func.isRequired,
  height: PropTypes.number.isRequired,
  imageUrl: PropTypes.string,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  instantValidation: PropTypes.bool,
  maxFileSize: PropTypes.number,
  maxWidth: PropTypes.number,
  minWidth: PropTypes.number,
  modalTitle: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  successMsg: PropTypes.string.isRequired,
  uploadErrorMsg: PropTypes.string.isRequired,
  validFileFormats: PropTypes.arrayOf(PropTypes.string),
  validationError: PropTypes.string,
  width: PropTypes.number,
};

ImageUploader.defaultProps = {
  allowSelect: true,
  instantValidation: false,
  maxFileSize: 1048576,
  showNotification: false,
  validFileFormats: ['png', 'jpeg'],
};

export const ImageUploaderField = ({
  name,
  labelProps,
  handleError,
  ...imageUploaderProps
}) => {
  // we want to inform the Form that there are errors
  // regarding the image field, to avoid unnecessary submits.
  // To do that we utilize the data param on the Field component.
  // By default, `data` is an object. We are memoizing it
  // to prevent unnecessary re-renders.
  const [error, setError] = useState();
  const data = useMemo(() => ({ error }), [error]);
  return (
    <Field
      validate={(value, values, meta) => {
        return meta?.data?.error;
      }}
      name={name}
      data={data}
    >
      {({ input, meta }) => (
        <div data-testid={`input-wrapper_${name}`}>
          <Label {...labelProps} />
          <ImageUploader
            {...imageUploaderProps}
            onChange={input.onChange}
            name={input.name}
            handleError={({ error, isValidating }) => {
              setError(error);
              handleError && handleError({ error, isValidating });
            }}
            imageUrl={input.value}
          />
          <ErrorWrapper>{meta.error || getError(meta)}</ErrorWrapper>
        </div>
      )}
    </Field>
  );
};
